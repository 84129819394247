import React from 'react';
import {
  ArrowLeftCircleFill,
  ArrowRightCircleFill,
} from 'react-bootstrap-icons';
import { Carousel, Row, Container, Col, Card, CardDeck } from 'react-bootstrap';
import { useMPEvent } from '@src/util/mixpanel';
import localeTranslation from '@util/locale';

import * as styles from '@css/components/testimonials-carousel/testimonials.module.scss';

export const Testimonials = ({ data, locale }) => {
  const { trackPageInteraction } = useMPEvent();
  const eventData = {
    language: locale.toLowerCase(),
    url: `/${locale}/`,
    url_name: 'home',
    element: 'testimonials',
    action: 'scroll',
  };

  function clickPrev() {
    trackPageInteraction({
      ...eventData,
      value: 'left',
    });
  }

  function clickNext() {
    trackPageInteraction({
      ...eventData,
      value: 'right',
    });
  }

  const handleSlide = (eventKey, direction) => {
    if (direction === 'left') {
      clickNext();
    } else {
      clickPrev();
    }
  };

  const testimonialSlides = [];
  let slideGroup = [];

  for (let i = 0; i < data.testimonials.length; i += 1) {
    if (i % 3 === 0) {
      if (slideGroup.length) {
        testimonialSlides.push(slideGroup);
      }
      slideGroup = [];
    }
    slideGroup.push(data.testimonials[i]);
  }

  if (slideGroup.length) {
    testimonialSlides.push(slideGroup);
  }

  return (
    <div className={`${styles.testimonialsSection} py-4 mt-5`}>
      <div className="container">
        <h2 className="text-center mb-0 pt-3 font-weight-bold">
          {data.testimonialsHeader}
        </h2>
      </div>

      {/* Mobile */}
      <Carousel
        className="testimonials-carousel d-block d-lg-none"
        slide={true}
        interval={null}
        indicators={false}
        prevIcon={
          <ArrowLeftCircleFill
            color="#5b3892"
            aria-hidden="true"
            className="carousel-control-icon"
          />
        }
        prevLabel={localeTranslation[locale].previousSlide}
        nextIcon={
          <ArrowRightCircleFill
            color="#5b3892"
            aria-hidden="true"
            className="carousel-control-icon"
          />
        }
        nextLabel={localeTranslation[locale].nextSlide}
        onSlide={handleSlide}
      >
        {data.testimonials.map((testimonial) => (
          <Carousel.Item
            key={`mobile-testimonial-${testimonial.id}`}
            className={styles.carouselItem}
          >
            <Container>
              <Row>
                <Col sm={{ span: 8, offset: 2 }}>
                  <Card className={`${styles.testimonialCard} m-0`}>
                    <Card.Body className={styles.cardBody}>
                      {testimonial.author ? (
                        <blockquote className={`${styles.blockquote} mb-0`}>
                          <div
                            className={styles.testimonialText}
                            dangerouslySetInnerHTML={{
                              __html:
                                testimonial.quote.childMarkdownRemark.html,
                            }}
                          />
                          <footer className={styles.blockquoteFooter}>
                            <div>
                              <strong>{testimonial.author}</strong>
                            </div>
                            <div className="small">
                              {testimonial.attribution}
                            </div>
                          </footer>
                        </blockquote>
                      ) : (
                        <>
                          <span className={styles.testimonialStatNumber}>
                            {testimonial.re}%
                          </span>
                          <div
                            className={styles.testimonialText}
                            dangerouslySetInnerHTML={{
                              __html:
                                testimonial.quote.childMarkdownRemark.html,
                            }}
                          />
                        </>
                      )}
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </Container>
          </Carousel.Item>
        ))}
      </Carousel>

      {/* Desktop */}
      <Container>
        <Carousel
          className="testimonials-carousel d-none d-lg-block"
          slide={true}
          interval={null}
          prevIcon={
            <ArrowLeftCircleFill
              color="#5b3892"
              aria-hidden="true"
              className="carousel-control-icon"
            />
          }
          prevLabel={localeTranslation[locale].previousSlide}
          nextIcon={
            <ArrowRightCircleFill
              color="#5b3892"
              aria-hidden="true"
              className="carousel-control-icon"
            />
          }
          nextLabel={localeTranslation[locale].nextSlide}
          onSlide={handleSlide}
        >
          {testimonialSlides.map((testimonialSlide, index) => (
            <Carousel.Item
              key={`desktop-testimonial-slide-${index}`}
              className={styles.carouselItem}
            >
              <div className="px-3">
                <CardDeck>
                  {testimonialSlide.map((testimonial) => (
                    <Card
                      key={`desktop-testimonial-${testimonial.id}`}
                      className={styles.testimonialCard}
                    >
                      <Card.Body className={styles.cardBody}>
                        {testimonial.author ? (
                          <blockquote className={`${styles.blockquote} mb-0`}>
                            <div
                              className={styles.testimonialText}
                              dangerouslySetInnerHTML={{
                                __html:
                                  testimonial.quote.childMarkdownRemark.html,
                              }}
                            />
                            <footer className={styles.blockquoteFooter}>
                              <div>
                                <strong>{testimonial.author}</strong>
                              </div>
                              <div className="small">
                                {testimonial.attribution}
                              </div>
                            </footer>
                          </blockquote>
                        ) : (
                          <>
                            <span className={styles.testimonialStatNumber}>
                              {testimonial.re}%
                            </span>
                            <div
                              className={styles.testimonialText}
                              dangerouslySetInnerHTML={{
                                __html:
                                  testimonial.quote.childMarkdownRemark.html,
                              }}
                            />
                          </>
                        )}
                      </Card.Body>
                    </Card>
                  ))}
                </CardDeck>
              </div>
            </Carousel.Item>
          ))}
        </Carousel>
      </Container>
    </div>
  );
};
