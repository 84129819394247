/**
 * Return a list of resources that have been filtered.
 */
export function applySelectorFilters(
  resources,
  filterAge,
  filterCategory,
  filterTypeOfSupport,
) {
  return resources.filter((resource) => {
    // Apply filter by age.
    if (filterAge && filterAge !== 'skip' && filterAge !== 'both') {
      // Some resources are "both", never filter these out.
      // Otherwise filter this resource out if it doesn't match the filtered age.
      if (resource.age !== 'both' && resource.age !== filterAge) return false;
    }

    // Apply filter by wellness category.
    if (filterCategory && filterCategory !== 'skip') {
      const resourceWellnessCategories = [
        resource.wellnessCategory,
        resource.wellnessCategory2,
        resource.wellnessCategory3,
      ];
      // Filter out if this resource if it doesn't contain the selected
      // category.
      if (
        !resourceWellnessCategories.includes('any') &&
        !resourceWellnessCategories.includes(filterCategory)
      ) {
        return false;
      }
    }

    // Appy filter by type of support.
    if (filterTypeOfSupport) {
      // Filter out if this resource support type does not match the selected
      // support type filter.
      if (resource.typeOfSupport !== filterTypeOfSupport) {
        return false;
      }
    }

    // Show this resource.
    return true;
  });
}
