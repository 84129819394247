import React from 'react';
import { graphql } from 'gatsby';
import BackgroundImage from 'gatsby-background-image';
import { convertToBgImage } from 'gbimage-bridge';
import { ArrowRight } from 'react-bootstrap-icons';

import * as styles from '@css/components/hero-banner/hero-banner-item.module.scss';
import { Image } from '@src/interfaces/Image';
import { SmoothScrollLink } from './SmoothScrollLink';

export const HeroBannerItem = ({ data }: HeroBannerItemProps) => {
  const { heading, subheading, linkText, linkUrl, bannerImage } = data;
  const bgImage = convertToBgImage(bannerImage.gatsbyImageData);

  // Subheadings are optional
  let showSubheading = null;
  if (subheading) {
    showSubheading = (
      <div className="mb-3">
        <span className={styles.heroBannerItemSubheading}>{subheading}</span>
      </div>
    );
  }

  return (
    <BackgroundImage {...bgImage} className={styles.heroBannerCarouselItem}>
      <div className="container h-100 d-flex align-items-center">
        <div className={styles.heroSection}>
          <h2 className="mb-3">
            <span className={styles.heroBannerItemHeading}>{heading}</span>
          </h2>
          {showSubheading}
          <div>
            <SmoothScrollLink
              to={linkUrl}
              className={`btn btn-primary ${styles.heroBannerItemCta} rounded-0`}
            >
              {linkText} <ArrowRight />
            </SmoothScrollLink>
          </div>
        </div>
      </div>
    </BackgroundImage>
  );
};

export interface HeroBannerItemProps {
  data: HeroBannerItemData;
}

export interface HeroBannerItemData {
  id: string;
  heading: string;
  subheading?: string;
  linkText: string;
  linkUrl: string;
  bannerImage: Image;
}

export const query = graphql`
  fragment HeroBannerItem on ContentfulHeroBannerItem {
    id
    heading
    subheading
    linkText
    linkUrl
    bannerImage {
      title
      gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
    }
  }
`;
