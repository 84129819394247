import React from 'react';
import { graphql } from 'gatsby';
import { Container } from 'react-bootstrap';
import {
  ServiceTile,
  ServiceTileData,
} from '@src/components/serviceTiles/ServiceTile';

import * as styles from '@css/components/service-tiles/service-tiles.module.scss';
import { MPEventData } from '@src/util/mixpanel';

export const ServiceTiles = ({
  data,
  serviceHeader,
  analyticData,
}: ServiceTilesProps) => {
  const serviceTiles = data.map((service: ServiceTileData) => (
    <ServiceTile
      data={service}
      key={service.title}
      analyticData={analyticData}
    />
  ));
  return (
    <section className={styles.section} aria-labelledby="service-tiles-heading">
      <Container className="pt-5">
        <div className={`${styles.heading} pt-lg-3 mb-4 pb-3`}>
          <h1 id="service-tiles-heading">{serviceHeader}</h1>
        </div>
        <div className="pb-5 pt-lg-5 mb-lg-4">
          <div className="d-flex flex-column flex-lg-row">{serviceTiles}</div>
        </div>
      </Container>
    </section>
  );
};

interface ServiceTilesProps {
  data: ServiceTileData[];
  serviceHeader: string;
  analyticData: MPEventData;
}

export const query = graphql`
  fragment Service on ContentfulService {
    title
    slug
    description {
      description
    }
    buttonText
    buttonLink
    icon {
      title
      file {
        url
      }
    }
    color
  }
`;
