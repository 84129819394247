// extracted by mini-css-extract-plugin
export const bgParent = "landing-module--bgParent--1pT1n";
export const pageBg = "landing-module--pageBg--Qd_Qv";
export const footprints = "landing-module--footprints--33bwF";
export const fixedTab = "landing-module--fixedTab--19n7p";
export const button = "landing-module--button--RtlML";
export const close = "landing-module--close--1MMLd";
export const hero = "landing-module--hero--3J9Kj";
export const content = "landing-module--content--1TIyz";
export const contentRow = "landing-module--contentRow--xd58O";
export const header = "landing-module--header--2zWYd";
export const copy = "landing-module--copy--1k2dJ";
export const link = "landing-module--link--2qffd";
export const card = "landing-module--card--2iScH";
export const cardBody = "landing-module--cardBody--3bpww";
export const checklist = "landing-module--checklist--FGvec";
export const benefit = "landing-module--benefit--1NpQL";
export const ctaButtonLink = "landing-module--ctaButtonLink--3lL3S";
export const ctaSubText = "landing-module--ctaSubText--LYCcT";
export const ctaSubTextCta = "landing-module--ctaSubTextCta--18Rg8";
export const transition = "landing-module--transition--xC6wR";
export const privacy = "landing-module--privacy--1CKa0";
export const left = "landing-module--left--1FanU";
export const right = "landing-module--right--1GVPh";
export const checklistItem = "landing-module--checklistItem--3JpDX";
export const privCta = "landing-module--privCta--N8r3v";