import React, { useState, useEffect } from 'react';
import { graphql, Link } from 'gatsby';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';

import { X } from 'react-bootstrap-icons';

import PageLayout from '@components/layout/PageLayout';
import ProgramNavigator from '@components/modals/ProgramNavigator';
import SiteNotice from '@components/SiteNotice';
import { Testimonials } from '@src/components/Testimonials';

import * as styles from '@css/modules/landing.module.scss';

// These all work, but we don’t have good typing on our CSS modules yet
// @ts-expect-error
import greenCheck from '@css/img/check-green.png';
// @ts-expect-error
import footprints from '@css/img/hero/footprints.png';

import { GAEvent } from '@src/util/analytics/GAEvent';
import { useMPEvent } from '@util/mixpanel';
import { ResourceData } from '@src/interfaces/ResourceData';
import ResourceSelector from '@src/components/ResourceSelector';
import { HeroCarousel } from '@src/components/HeroCarousel';
import { TrendyBar } from '@src/components/TrendyBar';
import { ServiceTiles } from '@src/components/serviceTiles/ServiceTiles';
import { Sponsors } from '@src/components/Sponsors';
import sessionStorage from '@util/sessionStorage';
import { TooltipTrigger } from '@src/components/TooltipTrigger';
import locale from '@src/util/locale';
import { NodeLocale } from '@src/interfaces/NodeLocale';

const FixedTab = ({
  nodeLocale,
  text,
  ...props
}: {
  nodeLocale: NodeLocale;
  text: string;
  onClick?: () => void;
}) => {
  const [isHidden, setIsHidden] = useState(false);

  useEffect(() => {
    setIsHidden(sessionStorage.get('hideFixedTab'));
  }, []);

  if (isHidden) return null;
  return (
    <div className={`${styles.fixedTab} d-none d-lg-inline-block`}>
      <Button
        className={`${styles.button} btn-secondary`}
        aria-label={locale[nodeLocale].openProgramNavigationModal}
        {...props}
      >
        {text}
      </Button>
      <TooltipTrigger tooltipTitle={locale[nodeLocale].close}>
        <Button
          className={`${styles.close} btn p-1 text-primary`}
          onClick={() => {
            sessionStorage.set('hideFixedTab', true);
            setIsHidden(true);
          }}
          aria-label={locale[nodeLocale].closeProgramNavigationModal}
        >
          <X size={31} />
        </Button>
      </TooltipTrigger>
    </div>
  );
};

const Footprints = () => (
  <img src={footprints} alt="" className={styles.footprints} />
);

// @ts-expect-error
export default function Home({ data, pageContext }) {
  const resources: ResourceData[] = data.allContentfulResourcePage.edges.map(
    // @ts-expect-error
    ({ node }) => node,
  );

  const {
    node_locale: nodeLocale,
    serviceHeader,
    services,
    selector,
    crisisSupport,
    hopeForWellness,
    testimonials,
    privacyInformation: privacy,
    heroBannerItems,
    trendyBar,
    fixedTabText,
    ...d
  } = data.contentfulHomePage;

  const [showProgramNav, setShowProgramNav] = useState(false);
  const { trackPageLoad, trackPageInteraction } = useMPEvent();
  const pageLoadEventData = {
    language: nodeLocale.toLowerCase(),
    url: `/${nodeLocale}/`,
    url_name: 'home',
  } as const;

  useEffect(() => {
    trackPageLoad(pageLoadEventData);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  function clickContactProgramNavigator() {
    const eventData = {
      ...pageLoadEventData,
      url_name: 'self_guided_care_navigator_modal',
    };
    trackPageLoad(eventData);
    trackPageInteraction({
      ...eventData,
      element: 'program navigator button',
      action: 'open',
    });
    GAEvent.landingPage.openProgramNavigator();
  }

  return (
    // @ts-expect-error
    <PageLayout
      node_locale={nodeLocale}
      page={pageContext.pageName}
      pageTitle={pageContext.pageTitle}
    >
      <FixedTab
        nodeLocale={nodeLocale}
        onClick={() => {
          setShowProgramNav(!showProgramNav);
          clickContactProgramNavigator();
        }}
        text={fixedTabText}
      />
      <SiteNotice node_locale={nodeLocale} />
      <HeroCarousel data={heroBannerItems} nodeLocale={nodeLocale} />
      <TrendyBar data={trendyBar} />
      <ServiceTiles
        data={services}
        serviceHeader={serviceHeader}
        analyticData={pageLoadEventData}
      />

      {/* Resource Selector */}
      <ResourceSelector
        locale={nodeLocale}
        pageContext={pageContext}
        resources={resources}
        selector={selector}
      />

      <Sponsors
        data={{
          headingText: d.heroSecondarySponsors,
          sponsorLogos: d.heroSecondarySponsorsLogos,
        }}
      />

      {/* Testimonials */}
      <Testimonials
        locale={nodeLocale}
        data={{ testimonialsHeader: d.testimonialsHeader, testimonials }}
      />

      {/* Privacy Overview */}
      <div className="bg-secondary">
        <Container className={styles.privacy}>
          <Row className="mb-4 align-items-center">
            <Col className={styles.left} xs={12} md={6}>
              <h2>{privacy.header}</h2>
              <div
                dangerouslySetInnerHTML={{
                  __html: privacy.copy.childMarkdownRemark.html,
                }}
              />
              <Link
                to={`/${nodeLocale}/privacy`}
                className="btn btn-outline-light"
              >
                {privacy.button}
              </Link>
            </Col>
            <Col className={styles.right} xs={12} md={6}>
              <ul
                style={{
                  listStyleType: 'none',
                  margin: 0,
                  padding: 0,
                }}
              >
                {/* @ts-expect-error */}
                {privacy.checklist.map((item, i) => (
                  <li key={i}>
                    <span                      
                      className={`text-secondary ${styles.checklistItem}`}
                    >
                      <img className="mt-2 mr-2" alt="" src={greenCheck} />
                      <p>{item}</p>
                    </span>
                  </li>
                ))}
              </ul>
              <div className="position-relative">
                <a
                  href={`${
                    process.env.GATSBY_GREENSPACE_URL
                  }/self_guided/signup/?lang=${nodeLocale.toLowerCase()}`}
                  className={`btn btn-primary ${styles.privCta} btn-dark-shadow`}
                >
                  {privacy.ctaButton}
                </a>
                <Footprints />
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      {/* Program Navigator Modal */}
      <ProgramNavigator
        node_locale={nodeLocale}
        show={showProgramNav}
        setShow={setShowProgramNav}
      />
    </PageLayout>
  );
}

export const query = graphql`
  query($node_locale: String!) {
    contentfulHomePage(node_locale: { eq: $node_locale }) {
      node_locale
      pageTitle
      heroBannerItems {
        ...HeroBannerItem
      }
      trendyBar {
        ...LinkList
      }
      heroHeader
      heroBody
      heroAboutText
      heroAboutUrl
      benefits
      heroButtonText
      heroButtonSubtext
      heroButtonSubtextCta
      heroButtonSubtextUrl
      heroButtonUrl
      heroSecondarySponsors
      heroSecondarySponsorsLogos {
        title
        gatsbyImageData(height: 70, placeholder: BLURRED, formats: [AUTO, WEBP])
      }
      serviceHeader
      services {
        ...Service
      }
      selector {
        header
        subTitle
        questionOne
        questionOneOptionOne
        questionOneOptionTwo
        questionOneOptionThree
        questionTwo
        questionTwoOptionOne
        questionTwoOptionTwo
        questionTwoOptionThree
        questionTwoOptionFour
        questionTwoOptionFive
        questionTwoOptionSix
        questionTwoOptionSeven
        questionThree
        questionThreeOptionOne
        questionThreeOptionTwo
        questionThreeOptionThree
        questionThreeOptionFour
        skip
        reset
        showMore
        showLess
        empty {
          childMarkdownRemark {
            html
          }
        }
      }
      crisisHeaderBold
      crisisHeaderText
      crisisSupportHeader
      crisisSupport {
        index
        target
        action {
          raw
        }
        icon {
          title
          gatsbyImageData(
            width: 79
            layout: FIXED
            placeholder: BLURRED
            formats: [AUTO, WEBP]
          )
        }
        linkUrl
        linkText
        trackingLabel
      }
      hopeForWellness {
        childMarkdownRemark {
          html
        }
      }
      testimonialsHeader
      testimonials {
        id
        author
        quote {
          childMarkdownRemark {
            html
          }
        }
        style
        bg
        re
      }
      privacyInformation {
        header
        copy {
          childMarkdownRemark {
            html
          }
        }
        button
        checklist
        ctaButton
      }
      fixedTabText
    }
    allContentfulResourcePage(
      filter: { node_locale: { eq: $node_locale } }
      sort: { fields: featuredOrder, order: ASC }
    ) {
      edges {
        node {
          updatedAt
          id: contentful_id
          uuid
          node_locale
          featuredOrder
          title
          slug
          canonicalUrl
          externalLink
          tags
          age
          wellnessCategory
          wellnessCategory2
          wellnessCategory3
          typeOfSupport
          updatedAt
          provider {
            name
            logo {
              title
              gatsbyImageData(
                height: 60
                placeholder: BLURRED
                formats: [AUTO, WEBP]
              )
            }
            branding {
              background
              text
              tag
            }
            url
          }
          tileImage {
            title
            gatsbyImageData(
              height: 500
              placeholder: BLURRED
              formats: [AUTO, WEBP]
            )
          }
        }
      }
    }
  }
`;
