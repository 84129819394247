import React, { Ref } from 'react';

import Button from 'react-bootstrap/Button';
import { Circle, CheckCircle } from 'react-bootstrap-icons';

import * as styles from '@css/modules/landing-resource.module.scss';

interface OptionButtonProps {
  option: string;
  value: string;
  click: () => void;
  firstOptionRef?: Ref<HTMLButtonElement>;
  text: string;
}

export default function OptionButton({
  option,
  value,
  click,
  firstOptionRef,
  text,
}: OptionButtonProps) {
  const active = option === value;
  return (
    <Button
      className={`${styles.btn} ${active ? styles.selected : ''} ${
        active ? 'text-white' : 'text-secondary'
      } text-bold`}
      variant={active ? 'secondary' : 'white'}
      onClick={() => click()}
      role="radio"
      ref={firstOptionRef}
      aria-label={text}
    >
      <span className={styles.optionIconParent}>
        {active ? (
          <CheckCircle className={styles.optionIcon} />
        ) : (
          <Circle className={styles.optionIcon} />
        )}
      </span>
      {text}
    </Button>
  );
}
