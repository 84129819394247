import React from 'react';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Link } from 'gatsby';
import { LinkListData } from '@src/interfaces/LinkListData';
import * as styles from '@src/css/modules/trendy-bar.module.scss';
import { LinkedTextData } from '@src/interfaces/LinkedTextData';

export const TrendyBar = ({ data: { heading, links } }: TrendyBarProps) => {
  return (
    <div className={styles.trendyBar}>
      <Container>
        <Row>
          <Col
            className="d-flex align-items-center align-items-sm-start"
            xs={12}
            lg="auto"
          >
            <h2 className={styles.trendyBarHeading}>{heading}</h2>
          </Col>
          <Col className={styles.trendyLinks}>
            <ul style={{
              listStyleType: 'none',
              margin: 0,
              padding: 0,
              display: 'flex',
            }}>            
              {links.map((linkData) => (
                <li key={linkData.id} style={{marginRight: "1rem"}}>
                  <TrendyLink key={linkData.id} data={linkData} />
                </li>
              ))}
            </ul>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

const TrendyLink = ({ data: { text, url } }: TrendyLinkProps) => {
  if (/^https?:\/\//i.test(url)) {
    return (
      <Button href={url} className={styles.trendyLink}>
        {text}
      </Button>
    );
  }

  return (
    <Link to={url} className={`btn btn-secondary ${styles.trendyLink}`}>
      {text}
    </Link>
  );
};

interface TrendyLinkProps {
  data: LinkedTextData;
}

interface TrendyBarProps {
  data: LinkListData;
}
