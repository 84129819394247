import React, { useState, useEffect, useRef, Ref } from 'react';
import Container from 'react-bootstrap/esm/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import store from 'store';

import * as styles from '@css/modules/landing-resource.module.scss';

import OptionButton from '@components/OptionButton';
import {
  FilterResourceEventData,
  UnfilterResourceEventData,
  useMPEvent,
} from '@src/util/mixpanel';
import { applySelectorFilters } from '@src/util/filters';
import { useDebounceCallback } from '@react-hook/debounce';
import { PageContext, ResourceSelectorData } from '../interfaces/ResourceData';
import { ResourceGrid } from './ResourceGrid';

// The number of resources to show when the "Show More" button is clicked.
const DefaultShowMoreAmount = 6;

// The number of milliseconds to delay before sending the "Filter Resource" event.
const FilterResourceOptionsEventDelay = 2000; // 2 seconds

export interface ResourceSelectorProps {
  locale: 'en-CA' | 'fr-CA';
  pageContext: PageContext;
  resources: any[];
  selector: ResourceSelectorData;
}

interface IButtonOptions {
  value: string;
  text: string;
}

const ResourceSelector = ({
  locale,
  pageContext,
  resources,
  selector,
}: ResourceSelectorProps) => {
  const trackingData = {
    language: locale.toLowerCase() as 'en-ca' | 'fr-ca',
    url: `/${locale}/`,
    url_name: 'home',
    element: 'resource selector',
  };

  // Hooks
  const {
    trackPageInteraction,
    trackFilterResource,
    trackUnfilterResource,
  } = useMPEvent();
  const [age, setAge] = useState('');
  const [topic, setTopic] = useState('');
  const [supportType, setSupportType] = useState('');
  const [questionText, setQuestionText] = useState(selector.questionOne);
  const [showMore, setShowMore] = useState(DefaultShowMoreAmount);
  const [filteredResources, setFilteredResources] = useState(resources);
  const firstOptionButtonRef: Ref<HTMLButtonElement> = useRef(null);
  const [hasBeenReset, setHasBeenReset] = useState(false);
  const [hasBeenSelected, setHasBeenSelected] = useState(false);

  useEffect(() => {
    if (store.get('personalization')) {
      setAge(store.get('age') || '');
      setTopic(store.get('category') || '');
      setSupportType(store.get('typeOfSupport') || '');
    }
  }, []);

  useEffect(() => {
    // on selector updates, save preferences if personalization is on
    if (store.get('personalization')) {
      store.set('age', age);
      store.set('category', topic);
      store.set('typeOfSupport', supportType);
    }
  }, [age, topic, supportType]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const filtered = applySelectorFilters(resources, age, topic, supportType);
    setFilteredResources(filtered);
    setShowMore(6);
  }, [age, topic, supportType]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!age) setQuestionText(selector.questionOne);
    else if (!topic) setQuestionText(selector.questionTwo);
    else setQuestionText(selector.questionThree);
  }, [age, topic]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (hasBeenSelected) {
      firstOptionButtonRef.current?.focus();
    }
  }, [age, topic, hasBeenSelected]);

  // Event handlers
  const showMoreHandler = (value: number) => {
    setShowMore(value);
    trackPageInteraction({ ...trackingData, action: 'load more' });
  };

  const trackFilterResourcesHandler = useDebounceCallback(() => {
    trackFilterResource({
      action: 'filter_resources',
      source: 'homepage',
      age,
      topics: topic,
      support_type: supportType,
    } as FilterResourceEventData);
  }, FilterResourceOptionsEventDelay);

  const filterResetResourcesHandler = () => {
    setAge('');
    setTopic('');
    setSupportType('');
    setHasBeenReset(true);
    trackUnfilterResource({
      source: 'homepage',
      type: 'clear all',
    } as UnfilterResourceEventData);
  };

  // Option buttons for age, topic and support
  const ageOptions = [
    { value: 'youth', text: selector.questionOneOptionOne },
    { value: 'adult', text: selector.questionOneOptionTwo },
    { value: 'both', text: selector.questionOneOptionThree },
  ];

  const topicOptions = [
    { value: 'worry', text: selector.questionTwoOptionOne },
    { value: 'relationships', text: selector.questionTwoOptionTwo },
    { value: 'covid', text: selector.questionTwoOptionThree },
    { value: 'mood', text: selector.questionTwoOptionFour },
    { value: 'stress', text: selector.questionTwoOptionFive },
    { value: 'substances', text: selector.questionTwoOptionSix },
    { value: 'other', text: selector.questionTwoOptionSeven },
  ];

  const supportOptions = [
    { value: 'talking', text: selector.questionThreeOptionOne },
    { value: 'self-guided', text: selector.questionThreeOptionTwo },
    { value: 'articles', text: selector.questionThreeOptionThree },
    { value: 'online', text: selector.questionThreeOptionFour },
  ];

  const assignRef = (index: number, isAge: boolean) => {
    // only focus on 'youth' when the reset button has been hit,
    // otherwise it will focus on 'youth' on the first time the page renders
    if (index === 0) {
      // return true only when the reset button has been hit
      if (isAge) {
        return hasBeenReset;
      }
      // return true if the options are not age, but topic or support Type
      return true;
    }
    // return false if the button is not the first button of the options
    return false;
  };

  const optionButtonsElement = (
    options: IButtonOptions[],
    option: string,
    setOption: (arg: string) => void,
  ) => {
    const isAge = options === ageOptions;
    const isSupportType = options === supportOptions;
    return (
      <div className={styles.buttonParent}>
        {options.map(({ value, text }, index) => (
          <OptionButton
            key={value}
            value={value}
            option={option}
            firstOptionRef={
              assignRef(index, isAge) ? firstOptionButtonRef : null
            }
            click={() => {
              setOption(value);
              setHasBeenSelected(true);
              trackFilterResourcesHandler();
            }}
            text={text}
          ></OptionButton>
        ))}
        <Button
          variant="link"
          className={`${styles.reset} text-secondary`}
          onClick={
            isSupportType
              ? () => filterResetResourcesHandler()
              : () => {
                  setOption('skip');
                  trackFilterResourcesHandler();
                }
          }
        >
          {isSupportType ? selector.reset : selector.skip}
        </Button>
      </div>
    );
  };

  const optionButtons = () => {
    if (!age) {
      return optionButtonsElement(ageOptions, age, setAge);
    }
    return !topic
      ? optionButtonsElement(topicOptions, topic, setTopic)
      : optionButtonsElement(supportOptions, supportType, setSupportType);
  };

  return (
    <Container
      className={`${styles.wrapper}`}
      id="resources"
      aria-labelledby="resource-selector-heading"
      as="section"
    >
      <Row className={`${styles.selector}`}>
        <Col xs={12} className={`${styles.intro}`}>
          <h2 id="resource-selector-heading" className={`${styles.header}`}>
            {selector.header}
          </h2>
          <p className={styles.subTitle}>{selector.subTitle}</p>
        </Col>
        <Col xs={12}>
          <h3 className={`${styles.question}`}>{questionText}</h3>
        </Col>
        <Col xs={12} className="my-2">
          {/* TODO CONTENTFUL refactor as an array of question objects */}
          {optionButtons()}
        </Col>

        {/* Resources Grid */}
        <Col xs={12}>
          <ResourceGrid
            resources={filteredResources}
            pageName={pageContext.pageName}
            locale={locale}
            showMore={showMore}
            setShowMore={showMoreHandler}
            selector={selector}
          />
        </Col>
      </Row>
    </Container>
  );
};
export default ResourceSelector;
