import { Image } from '@src/interfaces/Image';
import { GatsbyImage } from 'gatsby-plugin-image';
import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

export const Sponsors = ({ data }: SponsorsProps) => {
  return (
    <Container id="sponsors">
      <Row className="justify-content-center">
        {/* Sponsors */}
        <Col lg={{ span: 8 }} className="text-charcoal">
          <h2 className="text-charcoal text-md-center mb-4">
            {data.headingText}
          </h2>
          <Row>
            {/* @ts-expect-error */}
            {data.sponsorLogos.map((logo, i) => (
              <Col
                className="mb-4 d-flex align-items-center justify-content-center"
                xs={6}
                md={3}
                key={i}
              >
                <GatsbyImage
                  image={logo.gatsbyImageData}
                  alt={logo.title}
                  className="img-fluid"
                />
              </Col>
            ))}
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

interface SponsorsProps {
  data: SponsorsData;
}

interface SponsorsData {
  headingText: string;
  sponsorLogos: Image;
}
