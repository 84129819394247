// extracted by mini-css-extract-plugin
export const testimonialsSection = "testimonials-module--testimonials-section--Rv-al";
export const carouselItem = "testimonials-module--carousel-item--3E5W3";
export const active = "testimonials-module--active--2EWNi";
export const carouselItemNext = "testimonials-module--carousel-item-next--iS7yX";
export const carouselItemPrev = "testimonials-module--carousel-item-prev--1AmLy";
export const testimonialCard = "testimonials-module--testimonial-card--1zXuE";
export const testimonialStatNumber = "testimonials-module--testimonial-stat-number--1DJ6d";
export const testimonialText = "testimonials-module--testimonial-text--9nkhE";
export const cardBody = "testimonials-module--card-body--3xKw6";
export const blockquote = "testimonials-module--blockquote--1TlT5";
export const blockquoteFooter = "testimonials-module--blockquote-footer--1fX1i";