import React from 'react';

import * as styles from '@css/components/service-tiles/service-tiles.module.scss';

import { MPEventData, useMPEvent } from '@src/util/mixpanel';
import { Card } from 'react-bootstrap';
import { ArrowRight } from 'react-bootstrap-icons';
import { SmoothScrollLink } from '../SmoothScrollLink';

const ServiceCard = ({ data }: ServiceCardProps) => {
  return (
    <>
      <img
        className={`${styles.icon} rounded-pill shadow`}
        src={data.icon.file.url}
        alt=""
      />
      <div className={styles.content}>
        <div>
          <Card.Header className={styles.header}>
            <h2
              style={{
                color: data.color,
              }}
              className="m-0 font-weight-bold"
            >
              {data.title}
            </h2>
          </Card.Header>
          <Card.Body className={styles.body}>
            {data.description.description}
          </Card.Body>
        </div>
      </div>
    </>
  );
};

export const ServiceTile = ({ data, analyticData }: ServiceTileProps) => {
  const { trackPageInteraction } = useMPEvent();

  const trackButtonClick = () => trackPageInteraction({
    ...analyticData,
    element: 'service tile button',
    action: 'click',
    value: data.slug,
  });

  return (
    <>
      <SmoothScrollLink
        to={data.buttonLink}
        className="d-block d-lg-none mb-3"
        aria-label={data.buttonText}
        onClick={trackButtonClick}
      >
        <Card className={styles.wrapper}>
          <ServiceCard data={data} />
          <Card.Footer className={styles.footer}>
            <ArrowRight
              className="d-block d-sm-none"
              color={data.color}
              size={25}
              aria-hidden="true"
            />
            <ArrowRight
              className="d-none d-sm-block"
              color={data.color}
              size={40}
              aria-hidden="true"
            />
          </Card.Footer>
        </Card>
      </SmoothScrollLink>

      <Card className={`${styles.wrapper} d-none d-lg-flex`}>
        <ServiceCard data={data} />
        <Card.Footer className={styles.footer}>
          <SmoothScrollLink
            to={data.buttonLink}
            className={`${styles.cta} btn btn-link font-weight-bold w-100 py-2 text-decoration-none`}
            style={{
              backgroundColor: data.color,
            }}
            onClick={trackButtonClick}
          >
            {data.buttonText}
          </SmoothScrollLink>
        </Card.Footer>
      </Card>
    </>
  );
};

interface ServiceCardProps {
  data: ServiceTileData;
}

interface ServiceTileProps {
  data: ServiceTileData;
  analyticData: MPEventData;
}

export interface ServiceTileData {
  title: string;
  slug: string;
  description: {
    description: string;
  };
  buttonText: string;
  buttonLink: string;
  icon: {
    title: string;
    file: {
      url: string;
    };
  };
  color: string;
}
