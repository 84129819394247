import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Cookies from 'js-cookie';

import * as styles from '@css/modules/site-notice.module.scss';

type LocaleString = 'en-CA' | 'fr-CA';

interface SiteNoticeProps {
  node_locale: LocaleString;
}

interface SiteNoticeData {
  node_locale: LocaleString;
  enabled: boolean;
  headline: {
    headline?: string;
  };
  linkTitle?: string;
  linkUrl?: string;
  createdAt: string;
  updatedAt: string;
}

interface SiteNoticeQuery {
  allContentfulSiteNotice: {
    edges: { node: SiteNoticeData }[];
  };
}

interface SiteNoticeLinkProps {
  linkTitle?: string;
  linkUrl?: string;
}

const DISMISSED_KEY = 'siteNotice';

const SiteNoticeLink = ({ linkTitle, linkUrl }: SiteNoticeLinkProps) => {
  if (!linkTitle) return null;
  return (
    <a className={styles.siteNoticeLink} href={linkUrl}>
      {linkTitle}
    </a>
  );
};

const useIsClient = () => {
  const [isClient, setIsClient] = React.useState(false);
  const key = isClient ? 'client' : 'server';

  React.useEffect(() => {
    setIsClient(true);
  }, []);

  return { isClient, key };
};

const useSiteNoticeData = (node_locale: LocaleString) => {
  const [siteNoticeData, setSiteNoticeData] = React.useState<SiteNoticeData>(
    null,
  );
  const { allContentfulSiteNotice } = useStaticQuery<SiteNoticeQuery>(graphql`
    query {
      allContentfulSiteNotice {
        edges {
          node {
            enabled
            linkTitle
            linkUrl
            headline {
              headline
            }
            updatedAt
            createdAt
            node_locale
          }
        }
      }
    }
  `);

  React.useEffect(() => {
    let _siteNoticeData: SiteNoticeData | null = null;

    for (const { node } of allContentfulSiteNotice.edges) {
      if (node.enabled && node.node_locale === node_locale) {
        if (!_siteNoticeData || _siteNoticeData.createdAt < node.createdAt) {
          _siteNoticeData = node;
        }
      }
    }

    setSiteNoticeData(_siteNoticeData);
  }, []);

  return siteNoticeData;
};

export default function SiteNotice({ node_locale }: SiteNoticeProps) {
  const [dismissed, setDismissed] = React.useState(true);
  const { isClient, key } = useIsClient();
  const siteNoticeData = useSiteNoticeData(node_locale);

  React.useEffect(() => {
    const updatedAt = siteNoticeData?.updatedAt;
    if (updatedAt) {
      setDismissed(Cookies.get(DISMISSED_KEY) === updatedAt);
    }
  }, [siteNoticeData]);

  if (!isClient || !siteNoticeData || dismissed) {
    return null;
  }

  const headline = siteNoticeData.headline.headline;
  const { linkTitle, linkUrl, updatedAt } = siteNoticeData;

  const dismissNotice = () => {
    Cookies.set(DISMISSED_KEY, updatedAt, { expires: 7 });
    setDismissed(true);
  };

  return (
    <div key={key} className={styles.siteNoticeWrapper}>
      <div className="container-xl py-3 text-bolder">
        <div className="d-flex w-100 justify-content-center align-items-center position-relative">
          <div className="text-lg-center d-flex w-100 pr-5 px-lg-5 align-items-lg-center">
            <svg
              className="pr-2"
              aria-hidden="true"
              focusable="false"
              style={{ width: '2rem', height: '2rem', flexShrink: 0 }}
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512"
            >
              <path
                fill="currentColor"
                d="M256 8C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm0 110c23.196 0 42 18.804 42 42s-18.804 42-42 42-42-18.804-42-42 18.804-42 42-42zm56 254c0 6.627-5.373 12-12 12h-88c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h12v-64h-12c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h64c6.627 0 12 5.373 12 12v100h12c6.627 0 12 5.373 12 12v24z"
              ></path>
            </svg>
            <div>
              <span>{headline}</span>
              &nbsp;
              <SiteNoticeLink linkTitle={linkTitle} linkUrl={linkUrl} />
            </div>
          </div>
          <button
            type="button"
            className="close position-absolute rounded-circle d-flex justify-content-center align-items-center"
            style={{
              fontSize: '0.75rem',
              width: '1.5rem',
              height: '1.5rem',
              right: 0,
              borderWidth: 2,
              borderStyle: 'solid',
              borderColor: 'currentColor',
              color: 'currentColor',
              opacity: 1,
              lineHeight: 1,
            }}
            aria-label="Close"
            onClick={dismissNotice}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      </div>
    </div>
  );
}
