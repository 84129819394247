import React, { useState } from 'react';
import {
  HeroBannerItem,
  HeroBannerItemData,
} from '@src/components/HeroBannerItem';

import { Carousel } from 'react-bootstrap';
import { useMPEvent } from '@util/mixpanel';
import { NodeLocale } from '@src/interfaces/NodeLocale';
import locale from '@util/locale';

export const HeroCarousel = ({ data, nodeLocale }: HeroBannerCarouselProps) => {
  const [activeIndex, setActiveIndex] = useState(0);

  const { trackPageInteraction } = useMPEvent();
  const pageLoadEventData = {
    language: nodeLocale,
    url: `/${nodeLocale}/`,
    url_name: 'home',
  } as const;

  const bannerItems = data.map((heroBannerItem: HeroBannerItemData, index) => (
    <Carousel.Item key={`hero-carousel-item-${heroBannerItem.id}`}>
      <HeroBannerItem data={heroBannerItem} />
    </Carousel.Item>
  ));

  const onSelect = (
    eventKey: number,
    event: Record<string, unknown> | null,
  ) => {
    if (!event) {
      return;
    }
    trackPageInteraction({
      ...pageLoadEventData,
      element: 'hero banner',
      action: 'navigate',
      value: eventKey.toString(),
    });
    setActiveIndex(eventKey);
  };

  const customAccessibleIndicators = () => (
    <div className="carousel-indicators">
      {data.map((heroBannerItem: HeroBannerItemData, index) => {
        const isActiveSlide = index === activeIndex
        return (
          <button
            key={`hero-carousel-item-${heroBannerItem.id}-indicator`}
            onClick={() => setActiveIndex(index)}
            className={`carousel-indicator ${isActiveSlide ? 'active' : ''}`}
            aria-current={isActiveSlide ? true : undefined}
            aria-label={`${locale[nodeLocale].slide} ${index + 1}: ${heroBannerItem.heading}`}
          />
        );
      })}
    </div>
  )

  return (
    <Carousel
      controls={false}
      indicators={false}
      className="hero-banner-carousel"
      onSelect={onSelect}
      activeIndex={activeIndex}
    >
      {bannerItems}
      {customAccessibleIndicators()}
    </Carousel>
  );
};

interface HeroBannerCarouselProps {
  data: HeroBannerItemData[];
  nodeLocale: NodeLocale;
}
